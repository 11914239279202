<script setup>
  import { Authenticator } from "@aws-amplify/ui-vue";
  import "@aws-amplify/ui-vue/styles.css";
  import {Amplify, Auth, API } from 'aws-amplify';
  import awsconfig from "./aws-exports";

  const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  delete awsconfig.oauth
  Amplify.configure({
    ...awsconfig,
    Auth: {
      identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
      region: "ap-northeast-1",
      userPoolId: process.env.VUE_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
      cookieStorage: {
      domain: `.${process.env.VUE_APP_CUSTOM_DOMAIN}`,
      },
      oauth: {
        domain: process.env.VUE_APP_OAUTH_DOMAIN,
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: redirectUrl,
        redirectSignOut: redirectUrl,
        responseType: "code",
      },
    },
    API: {
      endpoints: [
        {
          name: "tokyo",
          endpoint: process.env.VUE_APP_API_ENDPOINT_TOKYO,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "non_tokyo",
          endpoint: process.env.VUE_APP_API_ENDPOINT_WHOLE_COUNTRY,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  });

</script>
<template>
  <div id="targetRef" class="demo">
    <authenticator :hide-sign-up="true">
      <template v-slot:footer>
        <View textAlign="center">
          <Text>
            ログインすると、
            <a
              :href="`https://${VUE_APP_CUSTOM_DOMAIN}/term_of_service.pdf`"
              target="_blank"
              rel="noreferrer"
            >
              利用規約
            </a>
            に同意したものとします<br>
          </Text>
          <Text>
            &copy; GA technologies AI Strategy Center. All Rights Reserved
          </Text>
        </View>
      </template>
      <template v-slot="{ signOut }">
        <button @click="signOut">Sign Out</button>
      <h1>マンション賃料予測</h1>
      <h5>現在の対応エリアは全国です。<br>
      </h5>
      <form @submit.prevent="postRequest" style="margin: auto; width: 300px;">
        <div>

          <label for="address">住所</label>
          <input type="text" id="address" v-model="request.address" placeholder="東京都港区六本木３丁目２−１" />
        </div>
        <div>
          <label for="layout">間取り</label>
          <select id="layout" name="layout" v-model="request.layout" placeholder="1K">
            <option disabled value="">間取りを選んでください</option>
            <option v-for="layoutOption in layoutOptions" :key="layoutOption" :value="layoutOption">{{ layoutOption }}</option>
          </select>
        </div>
        <div>
          <label for="footprint">平米数</label>
          <input type="text" id="footprint" v-model="request.footprint" placeholder="22.21" />
          <div v-if="v$.request.footprint.$error">
            <span v-if="!v$.request.footprint.required">平米数は必須です</span>
            <span v-if="!v$.request.footprint.numeric">平米数は数字でなければなりません</span>
            <span v-if="!v$.request.footprint.between">平米数は 15 から 500 の範囲内でなければなりません</span>
          </div>
        </div>
        <div>
          <label for="floor">所在階</label>
          <select id="floor" v-model="request.floor">
            <option disabled value="">所在階を選んでください</option>
            <option v-for="floor in floors" :key="floor" :value="floor">{{ floor }}</option>
          </select>
          <div v-if="floorExceedsTopFloor">警告: 所在階は総階数より大きいです</div>
        </div>
        <div>
          <label for="topfloor">総階数</label>
            <select id="topfloor" v-model="request.topfloor">
              <option disabled value="">総階数を選んでください</option>
              <option v-for="topfloor in topfloors" :key="topfloor" :value="topfloor">{{ topfloor }}</option>
            </select>
        </div>
        <div>
          <label for="completion">築年</label>
          <!-- <input type="text" id="completion" v-model="request.completion" placeholder="2020" /> -->
          <select id="completion" v-model="request.completion">
            <option disabled value="">築年を選んでください</option>
            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
          </select>
        </div>
      </form>
      <div>
        <br />
        <button @click="postRequest" :disabled="isRequesting">AI予測</button>
      </div>
      <div>
        <h2>{{showPrediction()}}</h2>
      </div>
    </template>
    </authenticator>
  </div>
</template>


<script>
import useValidate from '@vuelidate/core'
import { required, numeric, between } from '@vuelidate/validators'

export default {
  name: 'App',
  data() {
    const currentYear = new Date().getFullYear();
    return {
      v$: useValidate(),
      request: {
        address: '',
        //東京都港区赤坂６−３−８
        // 大阪府大阪市淀川区木川東３丁目,
        layout: '1K',
        //1K
        floor: 2,
        //3
        footprint: 25,
        //22.21
        topfloor: 10,
        //13
        completion: 2023,
        //2022
      },
      prediction: '',
      isRequesting: false,
      years: Array.from({ length: currentYear - 1963 }, (v, k) => 1965 + k),
      floors: Array.from({ length: 65 }, (_, i) => i + 1),
      topfloors: Array.from({ length: 65 }, (_, i) => i + 1),
      layoutOptions: [
        '1R', '1K', '1DK', '1LDK', '1LK', '1SDK', '1SK', '1SLDK', '1SLK',
        '2K', '2DK', '2LDK', '2LK', '2SDK', '2SK', '2SLDK', '2SLK',
        '3K', '3DK', '3LDK', '3LK', '3SDK', '3SK', '3SLDK', '3SLK',
        '4K', '4DK', '4LDK', '4LK', '4SDK', '4SK', '4SLDK',
        '5K', '5DK', '5LDK', '5SDK', '5SK', '5SLDK',
        '6K', '6DK', '6LDK', '6SLDK',
        '7K', '7DK', '7LDK', '7SLDK',
        '8DK', '8LDK', '8SLDK',
        '9LDK',
        '10K', '10DK', '10LDK', '10SLDK',
        '11DK', '11SK',
        '20LDK'
      ]
    }
  },

  computed: {
    floorExceedsTopFloor() {
      const floor = parseInt(this.request.floor, 10);
      const topfloor = parseInt(this.request.topfloor, 10);
      return floor > topfloor;
    }
  },

  validations() {
    return {
      request: {
        address: { required },
        layout: { required },
        footprint: { required, numeric, between: between(15, 500) },
        floor: { required },
        topfloor: { required },
        completion: { required }
      }
    }
  },

  methods: {

    showPrediction() {
      if (typeof (this.prediction) == 'number') {
        var result = '予測賃料:' + ' ' + Math.round(this.prediction).toLocaleString("ja-JP") + '円'
        return result
      } else {
        this.prediction = ' '
        result = '予測賃料:' + this.prediction
        return result
      }

    },

    postRequest() {
      this.prediction = '0'
      console.log(this.v$)
      this.v$.$validate()
      if (this.v$.request.address === '') {
        alert('住所を入力してください');
        return;
      }
      if (this.floorExceedsTopFloor) {
        alert('所在階は総階数を超えないようにご確認ください');
        return;
      }
      if (this.v$.request.footprint.$error) {
        alert('平米数を15から500までの数値を入れてください');
        return;
      }


      if (this.isRequesting) return;

      this.isRequesting = true;

      let request_zenkoku = {
        address: String(this.request.address),
        layout: String(this.request.layout),
        floor: Number(this.request.floor),
        footprint: Number(this.request.footprint),
        topfloor: Number(this.request.topfloor),
        completion: Number(this.request.completion) * 100 + 1
      }

      let request_itosanken = {
        address: String(this.request.address),
        layout: String(this.request.layout),
        floor: Number(this.request.floor),
        footprint: Number(this.request.footprint),
        topfloor: Number(this.request.topfloor),
        completion: Number(this.request.completion) * 100 + 1
      }

      function contains(target, pattern) {
        var value = 0;
        pattern.forEach(function (Word) {
          value = value + target.includes(Word);
        });
        return (value === 1)
      }

      const apiName = 'tokyo';
      const path = '/';
      const myInit = {
        body: request_itosanken,
        headers: {
          'content-type': 'application/json'
        }
      }

      const apiName_zenkoku = "non_tokyo";
      const myInit_zenkoku = {
        body: request_zenkoku,
        headers: {
          'content-type': 'application/json'
        }
      }


      var arr_tokyo = ["東京都", "神奈川県", "埼玉県", "千葉県"]
      var arr_zenkoku = ["兵庫県","北海道","京都府","愛知県","大阪府","福岡県","徳島県", "香川県", "愛媛県", "高知県","佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県","宮城県","富山県","山形県","山梨県","岐阜県","岩手県","新潟県","栃木県","石川県","福井県","福島県","秋田県","群馬県","茨城県","長野県","青森県","静岡県","和歌山県","奈良県","山口県","岡山県","島根県","広島県","滋賀県","鳥取県","三重県"]
      
      let address = this.request.address
      if (address && contains(address, arr_zenkoku)) {
        API.post(apiName_zenkoku, path, myInit_zenkoku)
        .then((response) => {
          console.log('prediction:', response.prediction[0].rent)
          if (response.prediction[0].rent === null) {
            alert('一都三県以外の場合、丁目以降も入力してください');
          } else {
          this.prediction = JSON.stringify(response.prediction[0].rent)
          this.prediction = Number(this.prediction)
          }
        }).catch((error) => {
          console.log(error.response);
        }).finally(() => {
          this.isRequesting = false;
        });

      } else if (address && contains(address, arr_tokyo)) {
        API.post(apiName, path, myInit)
        .then((response) => {
          console.log('prediction:', response.prediction[0].rent)
          this.prediction = JSON.stringify(response.prediction[0].rent)
          this.prediction = Number(this.prediction)
        }).catch((error) => {
          console.log(error.response);
        }).finally(() => {
          this.isRequesting = false;
        });

      } else {
        alert('住所を正しく入力してください。')
        this.isRequesting = false;
      }

    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.underline {
  text-decoration: underline;
}

.promoted {
  font-style: italic;
}

.new {
  color: olivedrab;
}

.sold-out {
  color: red;
}

label {
  font-weight: bold;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

input+label {
  font-weight: bold;
  display: inline-flex;
  margin-right: 20px;
}

input[type="text"],
input[type="text"],
textarea,
select {
  display: block;
  width: 300px;
  padding: 6px 2.5px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-router] fieldset {
  display: none;
}
[data-amplify-authenticator] .amplify-divider {
  display: none;
}
[data-amplify-authenticator] form div:not(.federated-sign-in-container) button {
  display: none;
}
[data-amplify-authenticator] footer [data-amplify-footer] {
  display: none;
}
</style>
