import { createApp } from 'vue'
import App from './App.vue'

// import { Amplify } from 'aws-amplify'
// import config from './aws-exports'

// ローカルでもサーバーでも動くようにredirectURLを自前で制御する必要がある
// var redirectURLs = config.oauth.redirectSignIn.split(",")
// for (var i = 0; i < redirectURLs.length; i++) {
//     if (location.href.indexOf(redirectURLs[i]) >= 0) {
//         config.oauth.redirectSignIn = redirectURLs[i];
//         config.oauth.redirectSignOut = redirectURLs[i];
//     }
// }
// Amplify.configure(config)

createApp(App).mount('#app')